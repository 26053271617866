import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../utils/baseQueryFn';


const profileAPI = createApi({
    reducerPath: "profileAPI",
    baseQuery,
    endpoints: (builder) => ({
        getUserProfile: builder.query({
            query: () => ({
                url: `staff/profile`,
                method: "GET",
            }),
            transformResponse: (response) => response,
            providesTags: ["profile"],
        }),
        updateProfile: builder.mutation({
            query: ({ body }) => ({
                url: `staff/profile`,
                method: "PUT",
                body,
            }),
            transformResponse: (response) => response,
            invalidatesTags: ["profile"],
        }),
        getUploadURL: builder.mutation({
            query: ({extension,type}) => ({
                url: `upload?extension=${extension}&uploadType=${type}`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
        
    }),
});

export const { useGetUserProfileQuery, useUpdateProfileMutation, useGetUploadURLMutation } = profileAPI;
export default profileAPI;
