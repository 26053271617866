import { Card, Table, TableBody, TableContainer } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { LoadingScreen } from 'src/components/loading-screen';
import Scrollbar from 'src/components/scrollbar';
import { TableNoData, TablePaginationCustom, useTable, TableHeadCustom } from 'src/components/table';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { useGetAllTourDealTypesQuery } from 'src/services/affordable/tour-deals/tour-deals-api';
import TourDealsTypeTableRow from './tours-deals-type-table-row';

const TABLE_HEAD = [
    { id: 'name', label: 'Tour Deal Type' },
    { id: 'action', label: ''}
];

const defaultFilters = {
    name: '',
    ref: ''
};

export default function TourDealsTypeList() {

    const router = useRouter();

    const table = useTable({ defaultOrderBy: 'name' });

    const [tableData, setTableData] = useState([]);
    const [totalElements, setTotalElements] = useState(0);

    const [filters] = useState(defaultFilters);

    const { isLoading, isSuccess, data, error } = useGetAllTourDealTypesQuery({ page: table.page, size: table.rowsPerPage });

    const canReset =
        !!filters.name ||
        !!filters.ref;

    const notFound = (!tableData.length && canReset) || !tableData.length;

    const handleEditRow = useCallback(
        (row) => {
            const dataObject = {
                tourDealType: row
            }
            router.pushData(paths.dashboard.affordable.editTourDealType(row.id), dataObject);
        },
        [router]
    );

    useEffect(() => {
        if (isSuccess) {
            setTableData(data.content)
            setTotalElements(data.totalElements);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, data]);

    let tourDealsTypeContent;
    if (isLoading) {
        tourDealsTypeContent = <LoadingScreen />
    }
    else if (error) {
        tourDealsTypeContent = <span>Error</span>
    }
    else if (isSuccess) {
        tourDealsTypeContent = (
            <Card>
                <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                    <Scrollbar>
                        <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }} stickyHeader>
                            <TableHeadCustom
                                order={table.order}
                                orderBy={table.orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={tableData.length}
                                numSelected={table.selected.length}
                                onSort={table.onSort}
                                onSelectAllRows={(checked) =>
                                table.onSelectAllRows(
                                checked,
                                tableData.map((row) => row.id)
                                )}
                            />

                            <TableBody>
                                {tableData
                                    .map((row) => (
                                        <TourDealsTypeTableRow
                                            key={row.id}
                                            row={row}
                                            onEditRow={() => handleEditRow(row)}
                                        />
                                    ))}
                                <TableNoData notFound={notFound} />
                            </TableBody>
                        </Table>
                    </Scrollbar>
                </TableContainer>
                {tableData.length > 0 &&
                    <TablePaginationCustom
                        count={totalElements}
                        page={table.page}
                        rowsPerPage={table.rowsPerPage}
                        onPageChange={table.onChangePage}
                        onRowsPerPageChange={table.onChangeRowsPerPage}
                        dense={table.dense}
                        onChangeDense={table.onChangeDense}
                    />}
            </Card>
        );
    }

    return (
        <>
            {tourDealsTypeContent}


        </>
    );
}
