import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import ProductEditView from 'src/sections/product/view/product-edit-view';
import ProductCreateView from 'src/sections/product/view/product-create-view';
import { InvoiceCreateView, InvoiceDetailsView, InvoiceEditView } from 'src/sections/invoice/view';
import PartnerTypeEditView from 'src/sections/partners/view/partner-type-edit-view';
import PartnerTypeDetailView from 'src/sections/partners/view/partner-type-detail-view';
import PartnersDetailView from 'src/sections/partners/view/partners-detail-view';
import PartnersEditView from 'src/sections/partners/view/partners-edit-view';
import { CreatePlaceView } from 'src/sections/place/view';
import EditPlaceView from 'src/sections/place/view/edit-place-view';
import PlaceView from 'src/sections/place/view/place-view';
import { CreateNewPartnerTypeView, CreatePartnerView } from 'src/sections/partners/view';
import InquiryCreateView from 'src/sections/inquiry/view/inquiry-create-view';
import InquiryEditView from 'src/sections/inquiry/view/inquiry-edit-view';
import ViewPayment from 'src/sections/payment/view/view-payment';
import { CreateStaffView } from 'src/sections/user/staff/view';
import EditStaffView from 'src/sections/user/staff/view/edit-staff-view';
import CreateDriverView from 'src/sections/user/driver-guide/view/create-driver-view';
import EditDriverView from 'src/sections/user/driver-guide/view/edit-driver-view';
import ViewDriver from 'src/sections/user/driver-guide/view/driver-view';
import CreateLbDriverView from 'src/sections/user/lb-driver-guide/view/create-driver-view';
import EditLbDriverView from 'src/sections/user/lb-driver-guide/view/edit-driver-view';
import { CreateTourPackageView } from 'src/sections/affordable/view';
import EditTourPackageView from 'src/sections/affordable/view/edit-tour-package-view';
import ViewTourPackage from 'src/sections/affordable/view/view-tour-package';
import CreateTourDealTypeView from 'src/sections/affordable/view/create-tour-deal-type-view';
import EditTourDealTypeView from 'src/sections/affordable/view/edit-tour-deal-type-view';
import CreateTourDealView from 'src/sections/affordable/view/create-tour-deal-view';
import EditTourDealView from 'src/sections/affordable/view/edit-tour-deal-view';
import ViewTourDeal from 'src/sections/affordable/view/view-tour-deal';
import CreateTourPlanView from 'src/sections/affordable/view/create-tour-plan-view';
import EditTourPlanView from 'src/sections/affordable/view/edit-tour-plan-view';
import ViewTourPlan from 'src/sections/affordable/view/view-tour-plan';

// ----------------------------------------------------------------------


// DRIVER REVIEW
const LeaderBoardListPage = lazy(() => import('src/pages/dashboard/driver-review/leaderboard'));
const DriverReviewListPage = lazy(() => import('src/pages/dashboard/driver-review/review'));
const CriteriaPage = lazy(() => import('src/pages/dashboard/driver-review/criteria'));

// DRIVER AVAILABILITY
const DriverAvailabilityPage = lazy(() => import('src/pages/dashboard/availability/view-all'));

// PROFILE
const ProfilePage = lazy(() => import('src/pages/dashboard/profile/profile'));

// PARTNERS
const NewPartnerPage = lazy(() => import('src/pages/dashboard/partners/new-partner'));
const ViewPartnersPage = lazy(() => import('src/pages/dashboard/partners/view-partners'));

// INQUIRY
const ApprovalPendingInquiryPage = lazy(() => import('src/pages/dashboard/inquiry/pending-inquiry'));
const RejectedInquiryPage = lazy(() => import('src/pages/dashboard/inquiry/rejected-inquiry'));
const AvailableToursPage = lazy(() => import('src/pages/dashboard/tours/available-tours'));
const AcceptedToursPage = lazy(() => import('src/pages/dashboard/tours/accepted-tours'));
const AssignedToursPage = lazy(() => import('src/pages/dashboard/tours/assigned-tours'));
const OngoingToursPage = lazy(() => import('src/pages/dashboard/tours/ongoing-tours'));
const ClosedToursPage = lazy(() => import('src/pages/dashboard/tours/closed-tours'));

// PROFILE
const PlacePage = lazy(() => import('src/pages/dashboard/place/place'));

// PAYMENT
const DriverGuidePage = lazy(() => import('src/pages/dashboard/payment/driver-guide'));
const PaymentCollectorPage = lazy(() => import('src/pages/dashboard/payment/payment-collector'));

// USER
const StaffPage = lazy(() => import('src/pages/dashboard/user/staff'));
const LbDriverGuidePage = lazy(() => import('src/pages/dashboard/user/lb-driver-guide'));
const ApprovedDriverGuidePage = lazy(() => import('src/pages/dashboard/user/approved-driver-guide'));
const PendingDriverGuidePage = lazy(() => import('src/pages/dashboard/user/pending-driver-guide'));
const UserPaymentCollectorPage = lazy(() => import('src/pages/dashboard/user/user-payment-collector'));


// AFFORDABLE
const TourPackagePage = lazy(() => import('src/pages/dashboard/affordable/tour-packages'));
const TourDealsPage = lazy(() => import('src/pages/dashboard/affordable/tour-deals'));
const TourDealsTypePage = lazy(() => import('src/pages/dashboard/affordable/tour-deal-type'));
const TourPlanPage = lazy(() => import('src/pages/dashboard/affordable/tour-plan'));


// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: 'driverReview',
        children: [
          { element: <LeaderBoardListPage />, index: true },
          { path: 'leaderboard', element: <LeaderBoardListPage /> },
          { path: 'review', element: <DriverReviewListPage /> },
          { path: 'criteria', element: <CriteriaPage /> },
          { path: 'criteria/:id/edit', element: <ProductEditView /> },
          { path: 'criteria/create', element: <ProductCreateView /> },
          { path: 'review/:id/edit', element: <InvoiceEditView /> },
          { path: 'review/create', element: <InvoiceCreateView /> },
          { path: 'review/:id/view', element: <InvoiceDetailsView /> },
        ],
      },
      { path: 'driverAvailability', element: <DriverAvailabilityPage />, index: true },
      { path: 'profile', element: <ProfilePage />, index: true },
      {
        path: 'partners',
        children: [
          { element: <NewPartnerPage />, index: true },
          { path: 'newPartnerType', element: <NewPartnerPage /> },
          { path: 'partner', element: <ViewPartnersPage /> },
          { path: 'newPartnerType/create', element: <CreateNewPartnerTypeView /> },
          { path: 'partner/create', element: <CreatePartnerView /> },
          { path: 'newPartnerType/:id/edit', element: <PartnerTypeEditView /> },
          { path: 'newPartnerType/:id/view', element: <PartnerTypeDetailView /> },
          { path: 'partner/:id/edit', element: <PartnersEditView /> },
          { path: 'partner/:id/view', element: <PartnersDetailView /> },
        ],
      },
      {
        path: 'place',
        children: [
          { element: <PlacePage />, index: true },
          { path: 'create', element: <CreatePlaceView /> },
          { path: ':id/edit', element: <EditPlaceView /> },
          { path: ':id/view', element: <PlaceView /> },
        ],
      },
      {
        path: 'inquiry',
        children: [
          { element: <ApprovalPendingInquiryPage />, index: true },
          { path: 'pendingInquiry', element: <ApprovalPendingInquiryPage /> },
          { path: 'rejectedInquiry', element: <RejectedInquiryPage /> },
          { path: 'create', element: <InquiryCreateView /> },
          { path: 'edit', element: <InquiryEditView /> },
          { path: 'view', element: <InquiryEditView /> },
        ],
      },
      {
        path: 'tour',
        children: [
          { element: <AvailableToursPage />, index: true },
          { path: 'availableTours', element: <AvailableToursPage /> },
          { path: 'acceptedTours', element: <AcceptedToursPage /> },
          { path: 'assignedTours', element: <AssignedToursPage /> },
          { path: 'ongoingTours', element: <OngoingToursPage /> },
          { path: 'closedTours', element: <ClosedToursPage /> }
        ],
      },
      {
        path: 'payment',
        children: [
          { element: <DriverGuidePage />, index: true },
          { path: 'driverGuide', element: <DriverGuidePage /> },
          { path: 'paymentCollector', element: <PaymentCollectorPage /> },
          { path: 'driverGuide/view', element: <ViewPayment /> },
        ],
      },
      {
        path: 'user',
        children: [
          { element: <StaffPage />, index: true },
          { path: 'staff', element: <StaffPage /> },
          { path: 'staff/create', element: <CreateStaffView /> },
          { path: 'staff/:id/edit', element: <EditStaffView /> },
          { path: 'staff/:id/view', element: <EditStaffView isView/> },

          { path: 'driverGuide', element: <LbDriverGuidePage /> },
          { path: 'driverGuide/create', element: <CreateLbDriverView /> },
          { path: 'driverGuide/:id/edit', element: <EditLbDriverView /> },

          { path: 'detailedDriverGuide/approved', element: <ApprovedDriverGuidePage /> },
          { path: 'detailedDriverGuide/pending', element: <PendingDriverGuidePage /> },
          { path: 'detailedDriverGuide/create', element: <CreateDriverView /> },
          { path: 'detailedDriverGuide/:id/edit', element: <EditDriverView /> },
          { path: 'detailedDriverGuide/:id/view', element: <ViewDriver /> },

          // { path: 'paymentCollector', element: <UserPaymentCollectorPage /> }
        ],
      },
      {
        path: 'affordable',
        children: [
          { element: <TourPackagePage />, index: true },
          { path: 'tourPackage', element: <TourPackagePage /> },
          { path: 'tourPackage/create', element: <CreateTourPackageView /> },
          { path: 'tourPackage/:id/edit', element: <EditTourPackageView /> },
          { path: 'tourPackage/:id/view', element: <ViewTourPackage /> },
          { path: 'tourDeals', element: <TourDealsPage /> },
          { path: 'tourDealsType', element: <TourDealsTypePage /> },
          { path: 'tourDeals/create', element: <CreateTourDealView /> },
          { path: 'tourDeals/:id/edit', element: <EditTourDealView /> },
          { path: 'tourDeals/:id/view', element: <ViewTourDeal /> },
          { path: 'tourDeals/tourDealsType/create', element: <CreateTourDealTypeView /> },
          { path: 'tourDeals/tourDealsType/:id/edit', element: <EditTourDealTypeView /> },
          { path: 'tourPlan', element: <TourPlanPage /> },
          { path: 'tourPlan/create', element: <CreateTourPlanView /> },
          { path: 'tourPlan/:id/edit', element: <EditTourPlanView /> },
          { path: 'tourPlan/:id/view', element: <ViewTourPlan /> },
        ],
      },
    ],
  },
];
