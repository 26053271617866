import { Button, Card, Container, Table, TableBody, TableContainer } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import Iconify from 'src/components/iconify';
import { LoadingScreen } from 'src/components/loading-screen';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import { TableNoData, TablePaginationCustom, useTable, TableHeadCustom, } from 'src/components/table';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { useDeletePartnerMutation, useGetAllPartnersMutation } from 'src/services/partners/partners-api';
import { homeActions } from 'src/store/store';
import { RouterLink } from 'src/routes/components';
import { useBoolean } from 'src/hooks/use-boolean';
import PartnersTableRow from '../partners-table-row';
import PartnerTableToolbar from '../partner-table-toolbar';
import CreateNewPartnerInfoForm from '../create-new-partner-info-form';

const TABLE_HEAD = [
  { id: 'name', label: 'Name' },
  { id: 'type', label: 'Type'},
  { id: 'address', label: 'Address'},
  { id: 'contact', label: 'Contact'},
  { id: '', label: ''}
];

const defaultFilters = {
  name: '',
  ref: ''
};

export default function ViewPartnersView() {
  const settings = useSettingsContext();

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const router = useRouter();

  const table = useTable({ defaultOrderBy: 'name' });

  const [tableData, setTableData] = useState([]);
  const [totalElements, setTotalElements] = useState(0);

  const [filters, setFilters] = useState(defaultFilters);
  const [searchTimer, setSearchTimer] = useState(null);
  const [searchKey, setSearchKey] = useState(null);
  const [isShow, setIsShow] = useState(false);

  const [getAllPartners, { isLoading, isSuccess, data, error }] = useGetAllPartnersMutation();

  const [deletePartner, { error: deletePartnerError, isSuccess: isSuccessDeletePartner }] = useDeletePartnerMutation();

  const canReset =
    !!filters.name ||
    !!filters.ref;

  const notFound = (!tableData.length && canReset) || !tableData.length;

  const quickEdit = useBoolean();
  const quickCreate = useBoolean();
  const [viewData, setViewData] = useState(null);

  const handleResetFilters = useCallback(() => {
    setSearchKey(null);
    setIsShow(false);
    setFilters(defaultFilters);
  }, []);

  const handleFilters = useCallback((name, value) => {
    table.onResetPage();

    setSearchKey(value);

    if (searchTimer) {
      clearTimeout(searchTimer);
    }

    if (!value) {
      handleResetFilters();
    }

    const newSearchTimer = setTimeout(() => {
      getAllPartners({ page: table.page, size: table.rowsPerPage, key: value });
    }, 1000);

    setSearchTimer(newSearchTimer);
  },[getAllPartners, handleResetFilters, searchTimer, table]);

  // Get All
  useEffect(() => {
    getAllPartners({ page: table.page, size: table.rowsPerPage, key: searchKey });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.page, table.rowsPerPage]);

  const handleDeleteRow = useCallback(
    (id) => {
      deletePartner(id);
    },
    [deletePartner]
  );

  // const handleEditRow = useCallback(
  //   (row) => {
  //     dispatch(
  //       homeActions.setPartnerData(row)
  //     );
  //     router.push(paths.dashboard.partners.editPartner(row.uniqueId));
  //   },
  //   [dispatch, router]
  // );

  const handleViewRow = useCallback(
    (row) => {
      dispatch(
        homeActions.setPartnerData(row)
      );
      router.push(paths.dashboard.partners.viewPartner(row.uniqueId));
    },
    [dispatch, router]
  );

  const handleEditRow = useCallback((row) => {
    if(!quickEdit.value){
        setViewData(row);
        quickEdit.onTrue();
    }
  },[quickEdit]);

  const handleCreateView = useCallback(() => {
      quickCreate.onTrue();
  },[quickCreate]);

  useEffect(() => {
    if (isSuccess) {
      setTableData(data.content)
      setTotalElements(data.totalElements);
      if (searchKey) {
        setIsShow(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, data]);

  useEffect(() => {
    if (deletePartnerError) {
      enqueueSnackbar('Deletion Failed!', { variant: 'error' });
    }
    else if (isSuccessDeletePartner) {
      enqueueSnackbar('Successfully Deleted!');

      handleResetFilters();
      getAllPartners({ page: table.page, size: table.rowsPerPage });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePartnerError, enqueueSnackbar, isSuccessDeletePartner]);

  const editRefreshCall = useCallback(() => {
    getAllPartners({ page: table.page, size: table.rowsPerPage, key: searchKey });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, table.page, table.rowsPerPage]);

  let partnersTypeContent;
  if (isLoading) {
    partnersTypeContent = <LoadingScreen />
  }
  else if (error) {
    partnersTypeContent = <span>Error</span>
  }
  else if (isSuccess) {
    partnersTypeContent = (
      <Card>
        <PartnerTableToolbar
          value={searchKey}
          onFilters={handleFilters}
        />

        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }} stickyHeader>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={table.selected.length}
                onSort={table.onSort}
                onSelectAllRows={(checked) =>
                  table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )}
              />

              <TableBody>
                {tableData
                  .map((row) => (
                    <PartnersTableRow
                      key={row.id}
                      row={row}
                      onViewRow={() => handleViewRow(row)}
                      onEditRow={() => handleEditRow(row)}
                      onDeleteRow={() => handleDeleteRow(row.uniqueId)}
                    />
                  ))}
                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        {tableData.length > 0 &&
          <TablePaginationCustom
            count={totalElements}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />}
      </Card>
    );
  }

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Partner"
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.driverReview.root,
          },
          {
            name: 'Partner',
            href: paths.dashboard.partners.partner,
          },
        ]}
        action={<Button
          component={RouterLink}
          // href={paths.dashboard.partners.createPartner}
          variant="contained"
          startIcon={<Iconify icon="mingcute:add-line" />}
          onClick={handleCreateView}
        >
          Add Partner
        </Button>}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      {partnersTypeContent}

      <CreateNewPartnerInfoForm open={quickCreate.value} onClose={quickCreate.onFalse} onSuccess={editRefreshCall}/>
      <CreateNewPartnerInfoForm currentProduct={viewData} open={quickEdit.value} onClose={quickEdit.onFalse} onSuccess={editRefreshCall}/>
    </Container>
  );
}
