import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../../utils/baseQueryFn';


const tourPackagesApi = createApi({
    reducerPath: "tourPackagesApi",
    baseQuery,
    endpoints: (builder) => ({
        getAllTourPackageNames: builder.query({
            query: () => {

                const queryParams = new URLSearchParams({
                    "queryAll": "true"
                });

                const apiUrl = `tourPackage/onlyname?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response.content,
            providesTags: ["tour-package"],
        }),
        getAllTourPackages: builder.mutation({
            query: ({ page, size, key }) => {
                let apiUrl = `tourPackage/getall?page=${page}&size=${size}`;

                if (key) {
                    apiUrl = `tourPackage/search?page=${page}&size=${size}&key=${key}`;
                }

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["tour-package"],
        }),
        createTourPackage: builder.mutation({
            query: (body) => ({
                url: `tourPackage/add`,
                method: "POST",
                body
            }),
            invalidatesTags: ["tour-package"],
        }),
        updateTourPackage: builder.mutation({
            query: ({ id, body }) => ({
                url: `tourPackage/update/${id}`,
                method: "PUT",
                body
            }),
            invalidatesTags: ["tour-package"],
        }),
        deleteTourPackage: builder.mutation({
            query: (id) => ({
                url: `tourPackage/delete/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["tour-package"],
        }),
    }),
});

export const { useGetAllTourPackageNamesQuery, useGetAllTourPackagesMutation, useCreateTourPackageMutation, useDeleteTourPackageMutation, useUpdateTourPackageMutation } = tourPackagesApi;
export default tourPackagesApi;
