import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
import { EmailAuthProvider, reauthenticateWithCredential, signInWithEmailAndPassword, updatePassword } from 'firebase/auth';
import { auth } from 'src/firebase/firebase';
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'FORGETPASSWORD') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';
const STORAGE_USER_KEY = 'user';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);
      const user = JSON.parse(sessionStorage.getItem(STORAGE_USER_KEY));

      if (accessToken && isValidToken(accessToken) && user) {
        setSession(accessToken);

        dispatch({
          type: 'INITIAL',
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {


    const response = await signInWithEmailAndPassword(auth, email, password);
    response.user.getIdToken().then((value) => {
      setSession(value);
    });
    const { user } = response;
    sessionStorage.setItem(STORAGE_USER_KEY, JSON.stringify(user));
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  }, []);

  // FORGET PASSWORD
  const forgetPassword = useCallback(async (email) => {


    // const response = await axios.post(endpoints.auth.register, data);

    // const { accessToken, user } = response.data;

    // sessionStorage.setItem(STORAGE_KEY, accessToken);

    // dispatch({
    //   type: 'FORGETPASSWORD',
    //   payload: {
    //     user,
    //   },
    // });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    sessionStorage.removeItem(STORAGE_USER_KEY)
    dispatch({
      type: 'LOGOUT',
    });
  }, []);


  // CHANGEPASSWORD

  const changePassword = useCallback(async (currentPassword, newPassword) => {

    if (auth != null) {
      const { email } = auth.currentUser;
      const credential = EmailAuthProvider.credential(
        email, currentPassword);
      await reauthenticateWithCredential(auth.currentUser, credential);
      await updatePassword(auth.currentUser, newPassword);
    }
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      forgetPassword,
      logout,
      changePassword
    }),
    [login, logout, forgetPassword, changePassword, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
