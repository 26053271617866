import { Button, Card, Container, Table, TableBody, TableContainer } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { paths } from 'src/routes/paths';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { useRouter } from 'src/routes/hooks';
import { TableNoData, TablePaginationCustom, useTable, TableHeadCustom } from 'src/components/table';
import { useGetAllPartnerTypeMutationMutation } from 'src/services/partners/partners-api';
import { homeActions } from 'src/store/store';
import { LoadingScreen } from 'src/components/loading-screen';
import Scrollbar from 'src/components/scrollbar';
import { RouterLink } from 'src/routes/components';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import PartnersTypeTableRow from '../partners-type-table-row';
import PartnerTableToolbar from '../partner-table-toolbar';
import CreateNewPartnerTypeForm from '../create-new-partner-type-form';

const TABLE_HEAD = [
    { id: 'name', label: 'Partner Type' },
    { id: 'action', label: ''}
];

const defaultFilters = {
    name: '',
    ref: ''
};

export default function NewPartnerView() {
    const settings = useSettingsContext();

    const dispatch = useDispatch();

    const router = useRouter();

    const table = useTable({ defaultOrderBy: 'name' });

    const [tableData, setTableData] = useState([]);
    const [totalElements, setTotalElements] = useState(0);

    const [filters, setFilters] = useState(defaultFilters);

    const [searchTimer, setSearchTimer] = useState(null);
    const [searchKey, setSearchKey] = useState(null);

    const [getAllPartnerTypes, { isLoading, isSuccess, data, error }] = useGetAllPartnerTypeMutationMutation();

    const canReset =
        !!filters.name ||
        !!filters.ref;

    const notFound = (!tableData.length && canReset) || !tableData.length;

    const quickEdit = useBoolean();
    const quickCreate = useBoolean();
    const [viewData, setViewData] = useState(null);

    const handleResetFilters = useCallback(() => {
        setFilters(defaultFilters);
    }, []);

    const handleFilters = useCallback((name, value) => {
        table.onResetPage();

        setSearchKey(value);

        if (searchTimer) {
            clearTimeout(searchTimer);
        }

        if (!value) {
            handleResetFilters();
        }

        const newSearchTimer = setTimeout(() => {
            getAllPartnerTypes({ page: table.page, size: table.rowsPerPage, key: value });
        }, 1000);

        setSearchTimer(newSearchTimer);
    },[getAllPartnerTypes, handleResetFilters, searchTimer, table]);

    // Get All
    useEffect(() => {
        getAllPartnerTypes({ page: table.page, size: table.rowsPerPage, key: searchKey });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table.page, table.rowsPerPage]);

    const handleViewRow = useCallback(
        (row) => {
            dispatch(
                homeActions.setPartnerTypeData(row)
            );
            router.push(paths.dashboard.partners.viewPartnerType(row.id));
        },
        [dispatch, router]
    );

    // const handleEditRow = useCallback(
    //     (row) => {
    //         dispatch(
    //             homeActions.setPartnerTypeData(row)
    //         );
    //         router.push(paths.dashboard.partners.editPartnerType(row.id));
    //     },
    //     [dispatch, router]
    // );

    const handleEditRow = useCallback((row) => {
        if(!quickEdit.value){
            setViewData(row);
            quickEdit.onTrue();
        }
    },[quickEdit]);

    const handleCreateView = useCallback(() => {
        quickCreate.onTrue();
    },[quickCreate]);

    useEffect(() => {
        if (isSuccess) {
            setTableData(data.content)
            setTotalElements(data.totalElements);
        }
    }, [isSuccess, data]);

    const editRefreshCall = useCallback(() => {
        getAllPartnerTypes({ page: table.page, size: table.rowsPerPage, key: searchKey });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchKey, table.page, table.rowsPerPage]);

    let partnersTypeContent;
    if (isLoading) {
        partnersTypeContent = <LoadingScreen />
    }
    else if (error) {
        partnersTypeContent = <span>Error</span>
    }
    else if (isSuccess) {
        partnersTypeContent = (
            <Card>
                <PartnerTableToolbar
                    value={searchKey}
                    onFilters={handleFilters}
                />
                
                <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                    <Scrollbar>
                        <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }} stickyHeader>
                            <TableHeadCustom
                                order={table.order}
                                orderBy={table.orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={tableData.length}
                                numSelected={table.selected.length}
                                onSort={table.onSort}
                                onSelectAllRows={(checked) =>
                                table.onSelectAllRows(
                                checked,
                                tableData.map((row) => row.id)
                                )}
                            />

                            <TableBody>
                                {tableData
                                    .map((row) => (
                                        <PartnersTypeTableRow
                                            key={row.id}
                                            row={row}
                                            onViewRow={() => handleViewRow(row)}
                                            onEditRow={() => handleEditRow(row)}
                                        />
                                    ))}
                                <TableNoData notFound={notFound} />
                            </TableBody>
                        </Table>
                    </Scrollbar>
                </TableContainer>

                <TablePaginationCustom
                    count={totalElements}
                    page={table.page}
                    rowsPerPage={table.rowsPerPage}
                    onPageChange={table.onChangePage}
                    onRowsPerPageChange={table.onChangeRowsPerPage}
                    dense={table.dense}
                    onChangeDense={table.onChangeDense}
                />
            </Card>
        );
    }

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <CustomBreadcrumbs
                heading="Partner Type"
                links={[
                    {
                        name: 'Dashboard',
                        href: paths.dashboard.driverReview.root,
                    },
                    {
                        name: 'Partner Type',
                        href: paths.dashboard.partners.root,
                    },
                ]}
                action={<Button
                    component={RouterLink}
                    // href={paths.dashboard.partners.createNewPartnerType}
                    variant="contained"
                    startIcon={<Iconify icon="mingcute:add-line" />}
                    onClick={handleCreateView}
                >
                    Add Partner Type
                </Button>}
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />

            {partnersTypeContent}

            <CreateNewPartnerTypeForm open={quickCreate.value} onClose={quickCreate.onFalse} onSuccess={editRefreshCall}/>
            <CreateNewPartnerTypeForm currentProduct={viewData} open={quickEdit.value} onClose={quickEdit.onFalse} onSuccess={editRefreshCall}/>
        </Container>
    );
}
