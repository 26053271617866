import { Button, Container, Tab, Tabs } from '@mui/material'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import { paths } from 'src/routes/paths';
import { homeActions } from 'src/store/store';
import { RouterLink } from 'src/routes/components';
import TourDealsTypeList from '../tour-deals-type-list';
import TourDealsList from '../tour-deals-list';

export default function TourDealsView() {
    const settings = useSettingsContext();

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <CustomBreadcrumbs
                heading="Tour Deals"
                links={[
                    {
                        name: 'Dashboard',
                        href: paths.dashboard.driverReview.root,
                    },
                    {
                        name: 'Tour Deals',
                        href: paths.dashboard.affordable.tourDeals,
                    },
                    {
                        name: 'List'
                    },
                ]}
                action={<Button
                    component={RouterLink}
                    href={paths.dashboard.affordable.createTourDeals}
                    variant="contained"
                    startIcon={<Iconify icon="mingcute:add-line" />}
                >
                    Add Tour Deal
                </Button>}
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />

            <TourDealsList />

        </Container>
    )
}
