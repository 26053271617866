import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
// routes
import { MenuItem } from '@mui/material';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { useSnackbar } from 'src/components/snackbar';
import { paths } from 'src/routes/paths';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
// components
import { useRouter } from 'src/routes/hooks';
import FormProvider, {
    RHFAutocomplete,
    RHFSelect,
    RHFTextField,
} from 'src/components/hook-form';
import RHFDatepicker from 'src/components/hook-form/rhf-date-picker';
import Iconify from 'src/components/iconify';
import { countries } from 'src/assets/data';
import { useAddInquiryMutation, useUpdateInquiryMutation } from 'src/services/inquiry/inquiry-api';
import { formatDate } from 'src/utils/format-time';
import InquiryMemberDetails from './inquiry-member-details';
import InquriyApproveInfo from './inquriy-approve-info';

// ----------------------------------------------------------------------

const ageList = [
    "ADULT",
    "CHILDREN_OVER_6",
    "CHILDREN_UNDER_6_OR_12"
];

const leadSourceList = [
    "INSTAGRAM",
    "FACEBOOK",
    "WEBSITE",
    "EMAIL",
    "REFERRAL",
    "WHATSAPP"
];

const touristTypeList = ["SOLO", "COUPLE", "FAMILY", "GROUP"];
const leadStatusList = ["Contacted", "Interested", "Booked"];

export default function InquiryEditForm({ currentProduct, isView, isEdit, inquiryType = 'CUSTOM', packOrDealId = null, status = '' }) {
    const router = useRouter();

    const mdUp = useResponsive('up', 'md');

    const { enqueueSnackbar } = useSnackbar();

    const NewProductSchema = Yup.object().shape({
        touristType: Yup.string().required("Tourist type can't be empty"),
        bookieName: Yup.string().required("Bookie Name can't be empty"),
        bookieCountry: Yup.string().required("Country can't be empty"),
        contactNo: Yup.string()
            .required("ContactNo can't be empty")
            .test('isValidPhoneNumber', 'Invalid phone number', () => {
                const isValid = validatePhoneNumber();
                return isValid; 
            }),
        leadSource: Yup.string().required("Lead source can't be empty"),
        leadStatus: Yup.string().required("Lead status can't be empty"),
        inquiryDate: Yup.date()
            .required("Inquiry date can't be empty")
            .typeError("Please select a valid date")
            .nullable(),
        arrivalDate: Yup.date()
            .required("Arrival date can't be empty")
            .typeError("Please select a valid date")
            .nullable(),
        departureDate: Yup.date()
            .required("Departure date can't be empty")
            .typeError("Please select a valid date")
            .nullable(),
    });

    const defaultValues = useMemo(
        () => ({
            touristType: currentProduct?.tourist_type || '',
            bookieName: currentProduct?.tour_bookie?.name || '',
            bookieCountry: currentProduct?.tour_bookie?.country || '',
            contactNo: currentProduct?.tour_bookie?.contact_no || '',
            leadSource: currentProduct?.lead_source || '',
            leadStatus: currentProduct?.lead_status || '',
            ageCategory: currentProduct?.tour_bookie?.age_category || 'ADULT',
            inquiryDate: new Date(currentProduct?.arrival_date) || null,
            arrivalDate: new Date(currentProduct?.arrival_date) || null,
            departureDate: new Date(currentProduct?.departure_date) || null,
            tourMember: currentProduct?.tour_members?.length
                ? currentProduct.tour_members
                : [],
        }),
        [currentProduct]
    );

    const methods = useForm({
        resolver: yupResolver(NewProductSchema),
        defaultValues,
    });

    const [updateInquiry, { isSuccess: isSuccessUpdateInquiry, error: errorUpdateInquiry }] = useUpdateInquiryMutation();
    const [createInquiry, { isSuccess: isSuccessCreateInquiry, error: errorCreateInquiry }] = useAddInquiryMutation();

    const {
        watch,
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const values = watch();

    useEffect(() => {
        if (currentProduct) {
            reset(defaultValues);
        }
    }, [currentProduct, defaultValues, reset]);


    const onSubmit = handleSubmit(async (data) => {
        try {

            if (currentProduct) {
                const body = {
                    "arrival_date": formatDate(data.arrivalDate),
                    "departure_date": formatDate(data.departureDate),
                    "lead_source": data.leadSource,
                    "lead_status": data.leadStatus,
                    "tour_bookie_age_category": data.ageCategory,
                    "tour_bookie_contact_no": data.contactNo,
                    "tour_bookie_country": data.bookieCountry,
                    "tour_bookie_name": data.bookieName,
                    "tour_members": data.tourMember,
                    "tourist_type": data.touristType
                }
                await updateInquiry({ id: currentProduct.id, body })
            }
            else {
                const body = {
                    arrival_date: formatDate(data.arrivalDate),
                    booking_date: formatDate(data.inquiryDate),
                    departure_date: formatDate(data.departureDate),
                    inquiry_date: formatDate(data.inquiryDate),
                    inquiry_type: inquiryType,
                    lead_source: data.leadSource,
                    lead_status: data.leadStatus,
                    pack_or_deal_id: packOrDealId,
                    tour_bookie_age_category: data.ageCategory,
                    tour_bookie_contact_no: data.contactNo,
                    tour_bookie_country: data.bookieCountry,
                    tour_bookie_name: data.bookieName,
                    tour_members: data.tourMember,
                    tourist_type: data.touristType

                }
                await createInquiry(body)
            }

        } catch (error) {
            console.error(error);
        }
    });

    const validatePhoneNumber = () => {
        const country = values.bookieCountry;
        const phoneNumber = values.contactNo;
        const selectedCountry = countries.find((c) => c.label === country);

        if(!phoneNumber.startsWith(`+${selectedCountry.phone}`)){
            return false;
        }

        const countryCode = selectedCountry ? selectedCountry.code : '';
        const parsedNumber = parsePhoneNumberFromString(phoneNumber, countryCode);

        if (parsedNumber) {
            return (parsedNumber.isValid());
        }

        return false;
    };

    useEffect(() => {
        if (isSuccessUpdateInquiry || isSuccessCreateInquiry) {
            reset();
            enqueueSnackbar(currentProduct ? 'Update success!' : 'Create success!');
            router.push(paths.dashboard.inquiry.root);
        }

    }, [currentProduct, enqueueSnackbar, isSuccessCreateInquiry, isSuccessUpdateInquiry, reset, router])

    useEffect(() => {
        if (errorCreateInquiry || errorUpdateInquiry) {
            enqueueSnackbar(errorCreateInquiry ? errorCreateInquiry?.data?.message : errorUpdateInquiry?.data?.message, { variant: 'error' });
        }
    }, [enqueueSnackbar, errorCreateInquiry, errorUpdateInquiry]);

    useEffect(() => {
        const currentURL = window.location.href;
        const parts = currentURL.split("/");
        const value = parts[parts.length - 1];
        if (value === 'edit') {
            if (!currentProduct) {
                router.push(paths.dashboard.inquiry.root);
            }
        }
    }, [currentProduct, router]);

    const getCountryCode = () => {
        const country = values.bookieCountry;
        const selectedCountry = countries.find((c) => c.label === country);
        const countryCode = selectedCountry ? selectedCountry.phone : '';
        
        return countryCode;
    }

    const renderbasicInfo = (
        <>
            {mdUp && (
                <Grid md={4}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        Tourist Info
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Tourist Type
                    </Typography>
                </Grid>
            )}

            <Grid xs={12} md={8}>
                <Card>
                    {!mdUp && <CardHeader title="Tourist Info" />}

                    <Stack spacing={3} sx={{ p: 3 }}>
                        <RHFSelect
                            disabled={!!isView}
                            fullWidth
                            name="touristType"
                            label="Tourist Type"
                            InputLabelProps={{ shrink: true }}
                            PaperPropsSx={{ textTransform: 'capitalize' }}
                        >
                            {touristTypeList.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                    </Stack>
                </Card>
            </Grid>
        </>
    );

    const renderbookieInfo = (
        <>
            {mdUp && (
                <Grid md={4}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        Bookie Info
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Bookie Name, Bookie Country,Bookie ContactNo
                    </Typography>
                </Grid>
            )}

            <Grid xs={12} md={8}>
                <Card>
                    {!mdUp && <CardHeader title="Bookie Info" />}

                    <Stack spacing={3} sx={{ p: 3 }}>
                        <RHFTextField disabled={!!isView} name="bookieName" label="Bookie Name" />
                        {(isEdit || isView) && <RHFSelect
                            disabled={!!isView}
                            fullWidth
                            name="ageCategory"
                            label="Age"
                            InputLabelProps={{ shrink: true }}
                            PaperPropsSx={{ textTransform: 'capitalize' }}
                        >
                            {ageList.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </RHFSelect>}

                        <RHFAutocomplete
                            disabled={!!isView}
                            name="bookieCountry"
                            placeholder="Bookie Country"
                            label="Bookie Country"
                            options={countries.map((option) => option.label)}
                            getOptionLabel={(option) => option}
                            renderOption={(props, option) => {
                                const { code, label, phone } = countries.filter(
                                    (country) => country.label === option
                                )[0];

                                if (!label) {
                                    return null;
                                }

                                return (
                                    <li {...props} key={label}>
                                        <Iconify
                                            key={label}
                                            icon={`circle-flags:${code.toLowerCase()}`}
                                            width={28}
                                            sx={{ mr: 1 }}
                                        />
                                        {label} ({code}) +{phone}
                                    </li>
                                );
                            }}
                        />

                        <RHFTextField 
                            disabled={!!isView || values.bookieCountry === ''} 
                            name="contactNo" 
                            label="Bookie ContactNo" 
                        />

                        {
                            values.bookieCountry === ''
                            ?
                            <></>
                            :
                            <Stack component="span" direction="row" alignItems="center">
                                <Iconify icon="eva:info-fill" width={16} sx={{ mr: 0.5, color: 'text.secondary' }} />

                                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                    Bookie contact number should be: +{getCountryCode()}XXXXXXXXX
                                </Typography>
                            </Stack>
                        }
                    </Stack>
                </Card>
            </Grid>
        </>
    );

    const renderleadInfo = (
        <>
            {mdUp && (
                <Grid md={4}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        Lead Info
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Lead Source, Lead Status
                    </Typography>
                </Grid>
            )}

            <Grid xs={12} md={8}>
                <Card>
                    {!mdUp && <CardHeader title="Lead Info" />}

                    <Stack spacing={3} sx={{ p: 3 }}>
                        <RHFSelect
                            disabled={!!isView}
                            fullWidth
                            name="leadSource"
                            label="Lead Source"
                            InputLabelProps={{ shrink: true }}
                            PaperPropsSx={{ textTransform: 'capitalize' }}
                        >
                            {leadSourceList.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                        <RHFSelect
                            disabled={!!isView}
                            fullWidth
                            name="leadStatus"
                            label="Lead Status"
                            InputLabelProps={{ shrink: true }}
                            PaperPropsSx={{ textTransform: 'capitalize' }}
                        >
                            {leadStatusList.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                    </Stack>
                </Card>
            </Grid>
        </>
    );

    const renderbookieDateInfo = (
        <>
            {mdUp && (
                <Grid md={4}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        Inquiry & Booking Dates
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Inquiry Date, Arrival Date, Departure Date
                    </Typography>
                </Grid>
            )}

            <Grid xs={12} md={8}>
                <Card>
                    {!mdUp && <CardHeader title="Inquiry & Booking Dates" />}

                    <Stack spacing={3} sx={{ p: 3 }}>
                        {!currentProduct && <RHFDatepicker name="inquiryDate" label="Inquiry Date" maxDate={new Date()} disabled={!!isView} />}
                        <RHFDatepicker name="arrivalDate" label="Arrival Date" disabled={!!isView} />
                        <RHFDatepicker name="departureDate" label="Departure Date" minDate={values.arrivalDate} disabled={!!isView} />
                    </Stack>
                </Card>
            </Grid>
        </>
    );

    const renderActions = (
        <>
            {mdUp && <Grid md={4} />}
            <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <Box
                    sx={{ flexGrow: 1, pl: 3 }}
                />

                <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                    {!currentProduct ? 'Create' : 'Save Changes'}
                </LoadingButton>
            </Grid>
        </>
    );

    return (
        <>
            <FormProvider methods={methods} onSubmit={onSubmit}>
                <Grid container spacing={3}>
                    {renderbasicInfo}
                    {renderbookieInfo}
                    {renderleadInfo}
                    {renderbookieDateInfo}
                    {(values.touristType !== '' && values.touristType !== 'SOLO') && <InquiryMemberDetails touristType={values.touristType} isView={isView} />}
                    {(isView || status === 'REJECTED') ? null : renderActions}
                </Grid>
            </FormProvider>

            <Typography variant="subtitle1" sx={{ my: 5, textAlign: 'center', color: '#fd5534' }}>
                Add a tour plan below to approve the inquiry
            </Typography>

            {
                (status !== '' && !isView) && <InquriyApproveInfo dataObject={{ 'tourInquiry': currentProduct, 'status': status }} />
            }
        </>
    );
}

InquiryEditForm.propTypes = {
    currentProduct: PropTypes.object,
    isView: PropTypes.bool,
    isEdit: PropTypes.bool,
    inquiryType: PropTypes.string,
    packOrDealId: PropTypes.string,
    status: PropTypes.string,
};
